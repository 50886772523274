import {Stack, Toolbar} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import NavbarLink from "../shared/navbar-link";
import {DarkModeOutlined, LightModeOutlined} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import logo from "./../../assets/images/logo.png";

const DesktopHeader = () => {

    const {pathname} = useLocation();
    const {theme} = useSelector(selectUI);
    const dispatch = useDispatch();

    return (
        <Toolbar sx={{backgroundColor: "background.paper"}}>
            <Stack
                sx={{width: '100%'}}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Link to='/' style={{textDecoration: 'none'}}>
                    <img
                        src={logo}
                        alt=""
                        style={{width: 75, height: 75, objectFit: 'contain', objectPosition: 'center'}}
                    />
                </Link>

                <Stack direction="row" alignItems="center" spacing={3}>
                    <NavbarLink active={pathname === '/'} label="Home" path="/"/>
                    <NavbarLink active={pathname === '/about'} label="About Us" path="/about"/>
                    <NavbarLink active={pathname === '/services'} label="Services" path="/services"/>
                    <NavbarLink active={pathname === '/contact'} label="Contact Us" path="/contact"/>
                    <NavbarLink active={pathname === '/tracking'} label="Track & Trace Parcel" path="/tracking"/>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {theme === 'dark' ? (
                        <LightModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.6,
                                fontSize: 36,
                                cursor: 'pointer',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                backgroundColor: 'light.secondary'
                            }}/>
                    ) : (
                        <DarkModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.6,
                                fontSize: 36,
                                cursor: 'pointer',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                backgroundColor: 'light.secondary'
                            }}/>
                    )}
                </Stack>
            </Stack>
        </Toolbar>
    )
}

export default DesktopHeader;
