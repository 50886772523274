import {
    AccountBalanceOutlined, DeliveryDining,
    Info,
    LocalShipping,
    Money,
    Sailing,
    Security
} from "@mui/icons-material";

import c2 from "./../assets/images/2.jpg";
import c3 from "./../assets/images/f1.jpg";

import r1 from "./../assets/images/f4.jpg";

import l1 from "./../assets/images/f3.jpg";
import l2 from "./../assets/images/1.jpg";
import l3 from "./../assets/images/f2.jpg";
import l4 from "./../assets/images/l4.jpg";

const SERVICES = [
    {
        icon: <Info sx={{fontSize: 40}}/>,
        name: 'GLOBAL SEA TRANSPORT',
        description: 'Our Global Sea Transport service provides reliable and efficient solutions for large cargo shipments, ensuring door-to-door delivery with precision and care.'
    },
    {
        icon: <AccountBalanceOutlined sx={{fontSize: 40}}/>,
        name: 'WORLDWIDE LOGISTICS',
        description: "We manage end-to-end logistics for global distribution, offering expert coordination in packing, shipping, and handling for businesses of all sizes."
    },
    {
        icon: <Money sx={{fontSize: 40}}/>,
        name: 'FAST AIR DELIVERY',
        description: 'Our Fast Air Delivery service ensures quick and secure transportation of goods, combining speed with cost efficiency to meet urgent needs.'
    }
];


const EXPERTISE = [
    {
        title: "ELITE DIPLOMATIC COURIERS",
        description: "Specialized delivery for sensitive shipments to remote destinations, ensuring prompt and secure transport by dedicated couriers.",
        icon:  <DeliveryDining sx={{ fontSize: 32}} alt=""/>
    },
    {
        title: "24/7 DOORSTEP DELIVERY",
        description: "Experience seamless delivery with our round-the-clock service that caters to your needs, no matter the location or time.",
        icon: <LocalShipping sx={{ fontSize: 32}} alt=""/>
    },
    {
        title: "OCEAN FREIGHT EXPERTS",
        description: "Effortless coordination of large shipments via sea, with options for part-load or full container services up to 40 feet.",
        icon:  <Sailing sx={{ fontSize: 32}} alt=""/>
    },
    {
        title: "MAXIMUM GOODS SECURITY",
        description: "Your goods are our priority, with state-of-the-art facilities and secure handling ensuring the utmost protection at all times.",
        icon:  <Security sx={{ fontSize: 32}} alt=""/>
    }
];

const PAYMENT_SERVICES = [
    {
        icon: <Info sx={{fontSize: 40}}/>,
        name: 'Low Costing',
        description: 'Our rate for the services we offer is mind blowing, as we charge very low. We believe your satisfaction if our concern'
    },
    {
        icon: <AccountBalanceOutlined sx={{fontSize: 40}}/>,
        name: 'Safe & Secure',
        description: "You are safe here cause your safety is our concern, we have made all things secure and reliable for our customer to send and receive money securely"
    },

    {
        icon: <Money sx={{fontSize: 40}}/>,
        name: 'Live Support',
        description: 'We have a 24 / 7 hours customer service system putted in place to make sure all your request is been resolved as fast as possible'
    }
];

const WHY_CHOOSE_US = [
    {
        icon: <Info sx={{fontSize: 40}}/>,
        name: 'RELIABLE DELIVERY',
        description: 'We prioritize reliability, ensuring that your shipments are delivered on time, every time.'
    },
    {
        icon: <AccountBalanceOutlined sx={{fontSize: 40}}/>,
        name: 'GLOBAL REACH',
        description: "Our extensive network covers international routes, allowing us to deliver anywhere across the globe."
    },
    {
        icon: <Money sx={{fontSize: 40}}/>,
        name: 'AFFORDABLE RATES',
        description: 'Enjoy cost-effective logistics solutions without compromising on quality or speed.'
    },
    {
        icon: <Info sx={{fontSize: 40}}/>,
        name: '24/7 SUPPORT',
        description: 'Our dedicated team is available around the clock to assist with any inquiries or issues.'
    },
    {
        icon: <AccountBalanceOutlined sx={{fontSize: 40}}/>,
        name: 'SECURE HANDLING',
        description: 'We employ state-of-the-art security measures to ensure your cargo is handled safely and securely.'
    },
    {
        icon: <Money sx={{fontSize: 40}}/>,
        name: 'FLEXIBLE OPTIONS',
        description: 'Choose from a range of transportation modes, including air, sea, and road freight, tailored to your needs.'
    }
];

const BENEFITS = [
    {
        title: 'CUSTOMIZED SOLUTIONS',
        description: 'We offer personalized logistics plans to meet the unique requirements of your business.'
    },
    {
        title: 'END-TO-END TRACKING',
        description: "Track your shipments in real-time with our advanced tracking technology, ensuring transparency and peace of mind."
    },
    {
        title: 'EFFICIENT SUPPLY CHAIN',
        description: 'Streamline your supply chain with our comprehensive logistics services, boosting efficiency and reducing costs.'
    },
    {
        title: 'EXPERT TEAM',
        description: 'Our experienced professionals are committed to providing top-notch logistics solutions for your business.'
    }
];


const REVIEWS = [
    {
        user: {
            name: 'Aarav Sharma',
            position: 'Frequent User',
            image: r1,
        },
        text: 'This is by far the most reliable logistics company I have ever used. Their speed and care are unmatched!',
        rating: 5
    },
    {
        user: {
            name: 'Liam Brooks',
            position: 'Valued Client',
            image: c3,
        },
        text: 'Excellent service every time! They truly go above and beyond to meet customer needs.',
        rating: 4.9
    },
    {
        user: {
            name: 'Sophia Johnson',
            position: 'Regular Customer',
            image: c2,
        },
        text: 'Efficient and trustworthy. Their process is seamless from start to finish.',
        rating: 4.8
    }
];

const FAQs = [
    {
        question: 'How do I track my shipment?',
        answer: 'You can track your shipment by entering your tracking number on our website’s tracking page.'
    },
    {
        question: 'What are the available shipping options?',
        answer: 'We offer air, sea, and road freight services to accommodate various shipment needs and budgets.'
    },
    {
        question: 'How do I contact customer support?',
        answer: 'Our customer support team is available 24/7 via email at support@stringlobal.com or through our hotline.'
    },
    {
        question: 'What items are restricted from shipping?',
        answer: 'Restricted items include hazardous materials, illegal goods, and items prohibited by destination country regulations. Please review our restricted items policy for details.'
    },
    {
        question: 'Can I change my delivery address?',
        answer: 'Yes, you can update your delivery address before the shipment reaches the destination country. Contact customer support for assistance.'
    },
    {
        question: 'How do I calculate shipping costs?',
        answer: 'Use our shipping calculator on the website or contact our team with your package details for a quote.'
    }
];

const LEADERSHIP = [
    {
        image: l3,
        name: 'Sarah Johnson',
        position: 'Chief Executive Officer',
        email: 'sarah.johnson@stringlobal.com',
        linkedIn: 'https://linkedin.com/in/sarah-johnson',
        twitter: 'https://twitter.com/sarahjohnson',
        instagram: 'https://instagram.com/sarahjohnson'
    },
    {
        image: l2,
        name: 'Michael Carter',
        position: 'Chief Operating Officer',
        email: 'michael.carter@stringlobal.com',
        linkedIn: 'https://linkedin.com/in/michael-carter',
        twitter: 'https://twitter.com/michaelcarter',
        instagram: 'https://instagram.com/michaelcarter'
    },
    {
        image: l1,
        name: 'Emily Davis',
        position: 'Chief Financial Officer',
        email: 'emily.davis@stringlobal.com',
        linkedIn: 'https://linkedin.com/in/emily-davis',
        twitter: 'https://twitter.com/emilydavis',
        instagram: 'https://instagram.com/emilydavis'
    },
    {
        image: l4,
        name: 'James Lee',
        position: 'Chief Technology Officer',
        email: 'james.lee@stringlobal.com',
        linkedIn: 'https://linkedin.com/in/james-lee',
        twitter: 'https://twitter.com/jameslee',
        instagram: 'https://instagram.com/jameslee'
    }
];

const APP_INFO = {
    name: 'String Global',
    email: 'info@stringlobal.com',
    phone: '+1(813)-2786-533',
    address: '',
    whatsapp: '',
}

export const STRING_GLOBAL_DATA = {
    SERVICES,
    PAYMENT_SERVICES,
    WHY_CHOOSE_US,
    BENEFITS,
    REVIEWS,
    FAQs,
    EXPERTISE,
    LEADERSHIP,
    APP_INFO
};
