import {createTheme} from "@mui/material";

const light = createTheme({
    typography: {
        fontFamily: 'Outfit, GoogleSans, TTSquares, EuclidCircularA, EuclidCircularB, DM Sans'
    },
    shape: {
        borderRadius: 2
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#2496FF'
        },
        secondary: {
            main: '#FFD700'
        },
        background: {
            default: '#ededed',
            paper: '#ffffff'
        },
        text: {
            secondary: '#828395',
            primary: '#2f2f2f'
        },
        light: {
            secondary: 'rgba(187,161,79,0.15)',
            red: 'rgba(255,0,26,0.15)',
            grey: 'rgba(47,47,47,0.15)'
        }
    }
});


const dark = createTheme({
    typography: {
        fontFamily: 'Outfit,GoogleSans, TTSquares, EuclidCircularA, EuclidCircularB, DM Sans'
    },
    shape: {
        borderRadius: 2
    },
    palette: {
        mode: 'dark',
        background: {
            paper: '#212121',
            default: '#323232'
        },
        primary: {
            main: '#2496FF'
        },
        secondary: {
            main: '#FFD700'
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgba(141,141,141,0.7)'
        },
        light: {
            secondary: 'rgba(187,161,79,0.15)',
            red: 'rgba(255,0,26,0.15)',
            grey: 'rgba(47,47,47,0.15)'
        }
    },
});

export const THEMES = {light, dark};
