import {Stack, Toolbar, Typography} from "@mui/material";
import {DarkModeOutlined, LightModeOutlined, Menu} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {Link} from "react-router-dom";

const MobileHeader = () => {

    const dispatch = useDispatch();

    const {theme} = useSelector(selectUI);

    return (
        <Toolbar sx={{backgroundColor: "background.paper"}}>
            <Stack
                sx={{width: '100%'}}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}>
                    <Menu
                        color="secondary"
                        sx={{
                            padding: 0.8,
                            fontSize: 36,
                            cursor: 'pointer',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 16,
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            backgroundColor: 'light.secondary'
                        }}
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}
                    />
                    <Link to="/" style={{textDecoration: 'none'}}>
                        <Typography
                            variant="body1"
                            sx={{color: 'secondary.main', fontWeight: 700, textTransform: "uppercase"}}>
                            String Global
                        </Typography>
                    </Link>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {theme === 'dark' ? (<LightModeOutlined
                        color="secondary"
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 0.8,
                            fontSize: 36,
                            cursor: 'pointer',
                            backgroundColor: 'light.secondary',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 16,
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16
                        }}
                    />) : (<DarkModeOutlined
                        color="secondary"
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 0.8,
                            fontSize: 36,
                            cursor: 'pointer',
                            backgroundColor: 'light.secondary',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 16,
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16
                        }}
                    />)}
                </Stack>
            </Stack>
        </Toolbar>)
}

export default MobileHeader;
