import {Container, Typography} from "@mui/material";
import {STRING_GLOBAL_DATA} from "../../utils/data";

const Copyright = () => {
    return (
        <Container sx={{paddingY: 4}}>
            <Typography variant="body1" sx={{color: 'text.primary'}}>
                Copyright © 2025 {STRING_GLOBAL_DATA.APP_INFO.name} All Rights Reserved
            </Typography>
        </Container>
    )
}
export default Copyright;
