import {Stack, Toolbar} from "@mui/material";
import ButtonLink from "../shared/button-link";
import {CallOutlined, LocationOnOutlined, MailOutlined} from "@mui/icons-material";
import {STRING_GLOBAL_DATA} from "../../utils/data";

const DesktopTopBar = () => {
    return (
        <Toolbar variant="dense" sx={{backgroundColor: 'background.default'}}>
            <Stack direction="row" spacing={3} alignItems="center">
                <ButtonLink
                    link="#"
                    text="710 New Town Terrace Jensen Beach Florida, USA"
                    icon={
                        <LocationOnOutlined
                            sx={{
                                backgroundColor: 'light.secondary',
                                color: "secondary.main",
                                padding: 1,
                                fontSize: 36,
                                borderBottomRightRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomLeftRadius: 16,
                                borderTopLeftRadius: 16,
                                '&:hover': {
                                    transition: 'all 300ms ease-in-out',
                                    backgroundColor: 'light.secondary',
                                    color: 'secondary.main'
                                }
                            }}/>
                    }/>

                <ButtonLink
                    link={`mailto:${STRING_GLOBAL_DATA.APP_INFO.email}`}
                    text={`${STRING_GLOBAL_DATA.APP_INFO.email}`}
                    icon={
                        <MailOutlined
                            sx={{
                                backgroundColor: 'light.secondary',
                                color: "secondary.main",
                                padding: 1,
                                fontSize: 36,
                                borderBottomRightRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomLeftRadius: 16,
                                borderTopLeftRadius: 16,
                                '&:hover': {
                                    transition: 'all 300ms ease-in-out',
                                    backgroundColor: 'light.secondary',
                                    color: 'secondary.main'
                                }
                            }}/>
                    }/>

                <ButtonLink
                    link={`tel:${STRING_GLOBAL_DATA.APP_INFO.phone}`}
                    text={`${STRING_GLOBAL_DATA.APP_INFO.phone}`}
                    icon={
                        <CallOutlined
                            sx={{
                                backgroundColor: 'light.secondary',
                                color: "secondary.main",
                                padding: 1,
                                fontSize: 36,
                                borderBottomRightRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomLeftRadius: 16,
                                borderTopLeftRadius: 16,
                                '&:hover': {
                                    transition: 'all 300ms ease-in-out',
                                    backgroundColor: 'light.secondary',
                                    color: 'secondary.main'
                                }
                            }}/>
                    }/>
            </Stack>
        </Toolbar>
    )
}

export default DesktopTopBar;
