import {configureStore} from "@reduxjs/toolkit";
import uiReducer from "./../features/ui/ui-slice";
import messageReducer from "./../features/message/message-slice";
import trackingReducer from "./../features/tracking/tracking-slice";

import {CONSTANTS} from "../../utils/constants";

const theme = localStorage.getItem(CONSTANTS.LG_GLOBAL_CONNECTIONS_THEME_VARIANT) ?
    JSON.parse(localStorage.getItem(CONSTANTS.LG_GLOBAL_CONNECTIONS_THEME_VARIANT)) : 'dark';

const store = configureStore({
    reducer: {
        ui: uiReducer,
        message: messageReducer,
        tracking: trackingReducer
    },
    preloadedState: {
        ui: {theme, activePath: '/', drawerOpen: false},
    }
});

export default store;
