import {Box, Link, SwipeableDrawer, SpeedDial, SpeedDialAction} from "@mui/material";
import Header from "../header/header";
import Footer from "../footer/footer";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import Drawer from "../drawer/drawer";
import {ChatBubble, WhatsApp} from "@mui/icons-material";

const Layout = ({children}) => {

    const {drawerOpen} = useSelector(selectUI);
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                minHeight: '100vh',
                maxWidth: '100vw',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.default'
            }}>
            <Box>
                <Header/>
            </Box>
            <Box sx={{flexGrow: 1, mt: {xs: 6, lg: 13.3}}}>
                {children}
            </Box>
            <Box>
                <Footer/>
            </Box>

            <Box
                sx={{
                    zIndex: 1000,
                    position: "fixed",
                    bottom: {xs: 10, lg: 50},
                    right: {xs: 10, lg: 50}
                }}>
                <Link href="https://wa.me/19804599686" target="_blank" underline="none">
                    <SpeedDial
                        ariaLabel="Contact Support on WhatsApp"
                        sx={{position: 'absolute', bottom: 16, right: 16}}
                        icon={
                            <WhatsApp sx={{fontSize: 36, color: "#25D366"}}/>
                        }
                        open={true}>
                        <SpeedDialAction
                            icon={<ChatBubble/>}
                            tooltipTitle="Contact Support on WhatsApp"
                            tooltipOpen
                        />
                    </SpeedDial>

                </Link>
            </Box>

            <SwipeableDrawer
                open={drawerOpen}
                onClose={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(false))}
                onOpen={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}>
                <Drawer/>
            </SwipeableDrawer>
        </Box>
    )
}

export default Layout;
