import {Link} from "react-router-dom";
import {Stack, Typography} from "@mui/material";

const ButtonLink = ({icon, text, link}) => {
    return (
        <Link style={{textDecoration: 'none'}} to={link}>
            <Stack spacing={2} direction="row" alignItems="center">
                {icon}
                <Typography
                    variant="body2"
                    sx={{color: "text.primary"}}>
                    {text}
                </Typography>
            </Stack>
        </Link>
    )

}
export default ButtonLink;
