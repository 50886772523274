import Layout from "../../components/layout/layout";
import {Box, Button, CardMedia, Container, Divider, Grid, Typography} from "@mui/material";
import Overlay from "../../components/shared/overlay";
import banner from "./../../assets/images/banners/home.jpg";
import image2 from "./../../assets/images/services/ocean-freight-img.jpg";
import {STRING_GLOBAL_DATA} from "../../utils/data";
import Service from "../../components/shared/service";
import Expertise from "../../components/shared/expertise";
import Leader from "../../components/shared/leader";
import ContactForm from "../../components/shared/contact-form";
import {Link} from "react-router-dom";

const HomePage = () => {

    return (
        <Layout>
            <Box>
                <Overlay
                    children={
                        <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
                            <Container>
                                <Grid container={true} spacing={4} alignItems="center" justifyContent="flex-end">
                                    <Grid item={true} xs={12} md={6}>
                                        <Typography variant="h3" sx={{color: 'white', mb: 4}}>
                                            {STRING_GLOBAL_DATA.APP_INFO.name}
                                        </Typography>
                                        <Typography variant="body2" sx={{color: 'white', mb: 4, fontWeight: 400}}>
                                            {STRING_GLOBAL_DATA.APP_INFO.name} has been the best Logistic courier company, offering
                                            distribution, fulfillment and solutions to our valued clients throughout the
                                            USA and across the World. Our diplomatic Service: When deliveries for
                                            certain far off places are undertaken, to avoid a possible delay
                                        </Typography>
                                        <Box>
                                            <Grid container={true} spacing={4}>
                                                <Grid item={true} xs={12} md={6}>
                                                    <Link to='/tracking' style={{textDecoration: 'none'}}>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            disableElevation={true}
                                                            color="secondary"
                                                            fullWidth={true}
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                            }}>
                                                            Track & Trace Parcel
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6}>
                                                    <Link to='/contact-us' style={{textDecoration: 'none'}}>
                                                        <Button
                                                            size="large"
                                                            variant="outlined"
                                                            disableElevation={true}
                                                            color="secondary"
                                                            fullWidth={true}
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                borderWidth: 2,
                                                                color: "white",
                                                                borderColor: "white"
                                                            }}>
                                                            Contact Us
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>}
                    image={banner}
                    backgroundColor="#000000"/>

                <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                    <Container>
                        <Typography
                            sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}} variant="body2"
                            align="center">
                            We guarantee fast and safe transport for your packages
                        </Typography>
                        <Typography sx={{color: 'text.primary', mb: 2}} variant="h4" align="center">
                            Our Services
                        </Typography>
                        <Typography sx={{color: 'text.primary', mb: 4}} variant="body2" align="center">
                            String Global delivery service offer door to door delivery, Security vaults,
                            Delivery by sea, Hand
                            and face verification for your vault, Air freight, Logistics, Climate Controlled Vaults .
                        </Typography>
                        <Grid container={true} spacing={2} justifyContent="space-between">
                            {STRING_GLOBAL_DATA.SERVICES.map((service, index) => {
                                return (
                                    <Grid xs={12} md={4} item={true} key={index}>
                                        <Service service={service}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{py: 8, backgroundColor: 'background.default'}}>
                    <Container>
                        <Typography align="center" variant="h4" sx={{color: 'text.primary', mb: 2}}>
                            A few things we’re great at
                        </Typography>
                        <Typography align="center" variant="body2" sx={{color: 'text.secondary', mb: 4}}>
                            We lessen the chances of a possible delay in delivery Customer Support and
                            Customer Services: Since we are in a service industry, lot of care has to be
                            given to this area. Our policy towards the customer care is “what problems do
                            customers have and what resources do we have to solve them
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 3}}/>

                        <Grid container={true} spacing={2}>
                            {STRING_GLOBAL_DATA.EXPERTISE.map((expertise, index) => {
                                return (
                                    <Grid xs={12} md={6} item={true} key={index}>
                                        <Expertise expertise={expertise}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                    <Container>
                        <Grid spacing={4} container={true} justifyContent="space-between" alignItems="center">
                            <Grid item={true} xs={12} md={6}>
                                <CardMedia
                                    component="img"
                                    src={image2}
                                    sx={{
                                        borderRadius: 2,
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                        objectPosition: 'center'
                                    }}/>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}}>
                                        We provide international courier deliver & cargo service
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{color: 'text.secondary', mb: 2, fontWeight: 'bold'}}>
                                        String Global Delivery Service
                                    </Typography>
                                    <Typography variant="body2" sx={{color: 'text.secondary', mb: 1}}>
                                        We clear your goods through customs and pay all fees in one seamless transaction
                                        with our expert customs brokerage services for imports to the USA. We are well
                                        versed in all the latest U.S Customs regulations, including Import Security
                                        Filing (ISF 10+2). Our logistics professionals can streamline your entire
                                        importing operation. Call us today for more info.
                                    </Typography>

                                    <Divider variant="fullWidth" sx={{my: 3}}/>

                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}}>
                                                EXPORT SERVICE
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{color: 'text.secondary'}}>
                                                Importer Security Filing-ISF 10+2 is now mandatory for all importers via
                                                ocean container to the USA.
                                            </Typography>
                                        </Grid>

                                        <Grid item={true} xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}}>
                                                SEA FREIGHT
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{color: 'text.secondary'}}>
                                                We offer a wide array of choices when it comes to Ocean freight exports.
                                                We are a licensed NVOCC .
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}}>
                                                AIR FREIGHT
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{color: 'text.secondary'}}>
                                                Our export division is staffed with logistics pro’s in Air Freight
                                                utilizing the latest in technology.
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Typography
                                                variant="body1"
                                                sx={{color: 'text.primary', mb: 2, textTransform: "uppercase"}}>
                                                IMPORT SERVICE
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{color: 'text.secondary'}}>
                                                Importer Security Filing-ISF 10+2 is now mandatory for all importers via
                                                ocean container to the USA.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                    <Container>
                        <Box>
                            <Typography align="center" variant="h4" sx={{color: 'text.primary', mb: 2}}>
                                Our Team
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary', mb: 4}}>
                                We make sure that your delivery gets to you because we strive to better delivery, Cargo
                                Services Limited delivery service service is an international courier and cargo company.
                            </Typography>
                            <Grid container={true} spacing={2}>
                                {STRING_GLOBAL_DATA.LEADERSHIP.map((leader, index) => {
                                    return (
                                        <Grid item={true} key={index} xs={12} md={3}>
                                            <Leader leader={leader}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Container>
                </Box>

                <Box sx={{
                    py: 8,
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    paddingY: 8
                }}>
                    <Container>
                        <Box>
                            <Typography align="center" variant="h4" sx={{color: 'white', mb: 2}}>
                                Contact us
                            </Typography>
                            <Typography variant="body2" sx={{color: 'white', mb: 4}}>
                                We offers a wide variety of international import and customs brokerage services designed
                                to suit the needs of businesses worldwide. Our customs brokerage department is connected
                                via ABI to US Customs offering timely clearance of your goods from ports all across the
                                USA.
                            </Typography>
                            <Grid container={true} spacing={4} justifyContent="center">
                                <Grid item={true} xs={12} md={6}>
                                    <Box>
                                        <ContactForm
                                            title="Get in touch with us"
                                            caption="To get in touch with String Global, please call or email us. For site specific contact details please use the form below."
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </Layout>
    )
}

export default HomePage;
