import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {MESSAGE_ACTION_CREATORS, selectMessage} from "../../redux/features/message/message-slice";
import {useFormik} from "formik";
import * as yup from "yup";
import "yup-phone";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";

const ContactForm = ({title, caption}) => {

    const {messageLoading, messageError} = useSelector(selectMessage);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const showMessage = (message, options) => {
        enqueueSnackbar(message, options);
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            text: '',
            subject: ''
        },
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values, {resetForm}) => {
            dispatch(MESSAGE_ACTION_CREATORS.sendMessage({values, resetForm, showMessage}));
        },
        validationSchema: yup.object().shape({
            name: yup.string().required('Name required'),
            email: yup.string().email('Invalid email').required('email required'),
            phone: yup.string().phone('Invalid phone').required('phone required'),
            text: yup.string().required('Message required'),
            subject: yup.string().required('Message required'),
        })
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <Card
                sx={{backgroundColor: "rgba(0, 0, 0, 0.8)", backdropFilter: "blur(5px)"}}
                variant="elevation"
                elevation={0}>
                {messageLoading && <LinearProgress variant="query" color="secondary"/>}
                <CardContent>
                    <Typography variant="h4" align="center" sx={{color: 'white', mb: 2}}>
                        {title}
                    </Typography>
                    <Typography variant="body2" align="center" sx={{color: 'white', mb: 4}}>
                        {caption}
                    </Typography>
                    {messageError && (
                        <Alert sx={{mb: 4}} variant="standard" severity="error">
                            <AlertTitle>{messageError}</AlertTitle>
                        </Alert>
                    )}
                    <Box sx={{mb: 4}}>
                        <Box sx={{mb: 4}}>
                            <Typography variant="body2" sx={{color: 'white', mb: 1, fontWeight: 'bold'}}>
                                Name
                            </Typography>
                            <TextField
                                fullWidth={true}
                                placeholder="Enter name"
                                required={true}
                                label="Name"
                                type="text"
                                variant="outlined"
                                helperText={formik.touched.name && formik.errors.name}
                                name="name"
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                size="medium"
                                color="secondary"
                                sx={{backgroundColor: 'light.secondary', borderRadius: 1}}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} md={6}>
                                <Typography variant="body2" sx={{color: 'white', mb: 1, fontWeight: 'bold'}}>
                                    Phone
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    placeholder="Phone"
                                    required={true}
                                    label="Phone"
                                    type="tel"
                                    variant="outlined"
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    name="phone"
                                    error={Boolean(formik.touched.phone && formik.errors.phone)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'light.secondary', borderRadius: 1}}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Typography variant="body2" sx={{color: 'white', mb: 1, fontWeight: 'bold'}}>
                                    Email
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    placeholder="Email"
                                    required={true}
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    helperText={formik.touched.email && formik.errors.email}
                                    name="email"
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'light.secondary', borderRadius: 1}}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{mb: 4}}>
                        <Typography variant="body2" sx={{color: 'white', mb: 1, fontWeight: 'bold'}}>
                            Subject
                        </Typography>
                        <TextField
                            fullWidth={true}
                            placeholder="Enter subject"
                            required={true}
                            label="Subject"
                            type="text"
                            variant="outlined"
                            helperText={formik.touched.subject && formik.errors.subject}
                            name="subject"
                            error={Boolean(formik.touched.subject && formik.errors.subject)}
                            size="medium"
                            color="secondary"
                            sx={{backgroundColor: 'light.secondary', borderRadius: 1}}
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>

                    <Box sx={{mb: 4}}>
                        <Typography variant="body2" sx={{color: 'white', mb: 1, fontWeight: 'bold'}}>
                            Write Your Comment Here
                        </Typography>
                        <TextField
                            fullWidth={true}
                            placeholder="Message"
                            required={true}
                            label="Message"
                            type="text"
                            variant="outlined"
                            helperText={formik.touched.text && formik.errors.text}
                            name="text"
                            error={Boolean(formik.touched.text && formik.errors.text)}
                            size="medium"
                            color="secondary"
                            sx={{backgroundColor: 'light.secondary', borderRadius: 1}}
                            value={formik.values.text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline={true}
                            minRows={5}
                        />
                    </Box>

                    <LoadingButton
                        fullWidth={true}
                        loading={messageLoading}
                        loadingPosition="start"
                        loadingIndicator={<CircularProgress color="secondary"/>}
                        disabled={messageLoading}
                        startIcon={messageLoading ? <CircularProgress color="secondary"/> : null}
                        type="submit"
                        color="secondary"
                        disableElevation={true}
                        variant="contained"
                        sx={{textTransform: 'capitalize'}}
                        size="large">
                        Send Message
                    </LoadingButton>
                </CardContent>
            </Card>
        </form>
    )
}

export default ContactForm;
