import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const NavbarLink = ({label, active, path}) => {
    return (
        <Link to={path} style={{textDecoration: 'none'}}>
            <Button
                size="large"
                variant="text"
                sx={{
                    textTransform: 'capitalize',
                    color: active ? 'secondary.main' : 'text.secondary',
                    '&:hover': {
                        color: 'secondary.main',
                        transition: '300ms ease-in-out'
                    }
                }}>
                {label}
            </Button>
        </Link>
    )
}

export default NavbarLink;
