import {Box} from "@mui/material";
import Copyright from "../shared/copyright";

const TopBar = () => {
    return (
        <Box sx={{backgroundColor: 'background.default'}}>
            <Copyright/>
        </Box>
    )
}

export default TopBar
