const SPRING_GLOBAL_THEME_VARIANT = 'STRING_GLOBAL_THEME_VARIANT';
const SPRING_GLOBAL_TOKEN = 'STRING_GLOBAL_TOKEN';
const SPRING_GLOBAL_AUTH_DATA = 'STRING_GLOBAL_AUTH_DATA';
// const SERVER_BASE_URL = 'http://localhost:5000/api/v1';
// const SERVER_BASE_URL = 'https://cargoserviceslimited.herokuapp.com/api/v1';
const SERVER_BASE_URL = 'https://springglobal-api.onrender.com/api/v1';

export const CONSTANTS = {
    SPRING_GLOBAL_THEME_VARIANT,
    SPRING_GLOBAL_TOKEN,
    SPRING_GLOBAL_AUTH_DATA,
    SERVER_BASE_URL
};
