import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router";
import {THEMES} from "./utils/themes";
import {useSelector} from "react-redux";
import {selectUI} from "./redux/features/ui/ui-slice";
import HomePage from "./pages/home/home-page";
import AboutPage from "./pages/about/about-page";
import NotFoundPage from "./pages/404/not-found-page";
import ServicesPage from "./pages/services/services-page";
import ContactPage from "./pages/contact/contact-page";
import TrackingPage from "./pages/tracking/tracking-page";

function App() {
  const {theme} = useSelector(selectUI);
  return (
      <ThemeProvider theme={theme === 'dark' ? THEMES.dark : THEMES.light}>
        <CssBaseline enableColorScheme={true}/>
        <Routes>
          <Route element={<HomePage/>} path="/"/>
          <Route element={<AboutPage/>} path="/about"/>
          <Route element={<ContactPage/>} path="/contact"/>
          <Route element={<ServicesPage/>} path="/services"/>
          <Route element={<TrackingPage/>} exact={true} path="/tracking"/>
          <Route element={<NotFoundPage/>} path="*"/>
        </Routes>
      </ThemeProvider>
  );
}

export default App;
