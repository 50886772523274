import {Box, Container, Divider, Stack, Typography} from "@mui/material";
import DrawerLink from "../shared/sidebar-link";
import {useLocation} from "react-router-dom";
import {
    Home,
    HomeOutlined,
    Inbox,
    InboxOutlined,
    LocalShipping,
    LocalShippingOutlined,
    MiscellaneousServices,
    MiscellaneousServicesOutlined,
    SaveAlt,
    SaveAltOutlined
} from "@mui/icons-material";

const Drawer = () => {

    const {pathname} = useLocation();

    return (
        <Box
            sx={{
                minWidth: {xs: '90vw'},
                display: {xs: 'block', lg: 'none'},
                py: 2,
                backgroundColor: 'background.paper',
                minHeight: "100vh"
            }}>
            <Container>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    Main
                </Typography>

                <Divider sx={{my: 2}} variant="fullWidth" light={true}/>

                <Stack sx={{mb: 8}} direction="column" spacing={4}>
                    <DrawerLink
                        link="/"
                        active={pathname === '/'}
                        icon={
                            pathname === '/' ?
                                (<Home color="secondary"/>) :
                                (<HomeOutlined sx={{color: 'text.secondary'}}/>)
                        }
                        label="Home"
                    />

                    <DrawerLink
                        link="/services"
                        active={pathname === '/services'}
                        icon={
                            pathname === '/services' ?
                                (<MiscellaneousServices color="secondary"/>) :
                                (<MiscellaneousServicesOutlined sx={{color: 'text.secondary'}}/>)
                        }
                        label="Services"
                    />

                    <DrawerLink
                        link="/about"
                        active={pathname === '/deposit-money'}
                        icon={
                            pathname === '/about' ?
                                (<Inbox color="secondary"/>) :
                                (<InboxOutlined sx={{color: 'text.secondary'}}/>)
                        }
                        label="About"
                    />


                    <DrawerLink
                        link="/contact"
                        active={pathname === '/contact'}
                        icon={
                            pathname === '/contact' ?
                                (<SaveAlt color="secondary"/>) :
                                (<SaveAltOutlined sx={{color: 'text.secondary'}}/>)
                        }
                        label="Contact Us"
                    />

                    <DrawerLink
                        link="/tracking"
                        active={pathname === '/tracking'}
                        icon={
                            pathname === '/tracking' ?
                                (<LocalShipping color="secondary"/>) :
                                (<LocalShippingOutlined sx={{color: 'text.secondary'}}/>)
                        }
                        label="Tracking"
                    />


                </Stack>
            </Container>
        </Box>
    )
}

export default Drawer;
