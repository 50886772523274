import Layout from "../../components/layout/layout";
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    OutlinedInput,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Step,
    StepContent,
    StepLabel,
    Stepper,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {NumbersOutlined} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";
import Banner from "../../components/shared/banner";
import {selectTracking, TRACKING_ACTION_CREATORS} from "../../redux/features/tracking/tracking-slice";
import {useSnackbar} from "notistack";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import banner from "../../assets/images/banners/about.jpg";
import {STRING_GLOBAL_DATA} from "../../utils/data";


const TrackingPage = () => {

    const {shipmentLoading, shipmentError, shipment} = useSelector(selectTracking);

    const {enqueueSnackbar} = useSnackbar();

    const showMessage = (message, options) => {
        enqueueSnackbar(message, options);
    }

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            tracking: '',
        },
        onSubmit: (values, {resetForm, setSubmitting}) => {
     
            dispatch(TRACKING_ACTION_CREATORS.track({
                trackingID: values.tracking,
                resetForm,
                setSubmitting,
                showMessage
            }));
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: yup.object({
            tracking: yup.string().required('tracking required'),
        })
    });

    const getActiveStep = (stages) => {
        const activeStage = stages.find(stage => !stage.completed);
        return activeStage ? activeStage.step : 0;
    };
    
    return (
        <Layout>
            {shipmentLoading && <LinearProgress variant="query" color="secondary"/>}
            <Banner
                children={
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Container>
                            <Stack direction="column" spacing={4}>
                                <Typography align="center" variant="h3" sx={{color: 'white'}}>
                                    Tracking
                                </Typography>
                            </Stack>
                        </Container>
                    </Box>
                }
                links={[{label: 'Home', link: '/'}, {label: 'Tracking', link: '/tracking'}]}
                backgroundImage={banner}
            />
            <Box sx={{py: 8}}>
                <Container>
                    <Grid container={true} spacing={5}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography sx={{color: 'text.primary', mb: 2}} variant="h5">
                                Tracking
                            </Typography>
                            <Box>
                                <Typography sx={{color: 'text.primary', mb: 2}} variant="body2">
                                    If you are expecting to receive a delivery from {STRING_GLOBAL_DATA.APP_INFO.name}{" "}
                                    Shipping Department , then you can Track your Parcel or Shipment below:
                                </Typography>
                                <Typography sx={{color: 'text.secondary', mb: 2}} variant="body2">
                                    Our tracking system will tell you the current status of your delivery and when you
                                    can
                                    expect your parcel to be delivered
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Box sx={{}}>
                                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                    <Card variant="outlined" sx={{
                                        height: '100%',
                                        backgroundColor: 'background.transparent',
                                        backdropFilter: 'blur(4px)'
                                    }}>
                                        <CardContent>
                                            {shipmentError && (
                                                <Alert sx={{mb: 2}} severity="error">
                                                    <AlertTitle>{shipmentError}</AlertTitle>
                                                </Alert>
                                            )}

                                            <Box mb={4}>
                                                <Typography
                                                    mb={1} variant="body2"
                                                    sx={{color: 'secondary.main', fontWeight: 'bold'}}>
                                                    Tracking Number
                                                </Typography>
                                                <FormControl fullWidth={true} variant="outlined">
                                                    <InputLabel
                                                        htmlFor="tracking">Tracking ID</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth={true}
                                                        value={formik.values.tracking}
                                                        id="tracking"
                                                        name="tracking"
                                                        type="text"
                                                        endAdornment={
                                                            <InputAdornment
                                                                position="end">
                                                                <NumbersOutlined
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        color: 'secondary.main',
                                                                        padding: 1,
                                                                        fontSize: 36,
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        }
                                                        error={Boolean(formik.touched.tracking && formik.errors.tracking)}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Enter shipment tracking id"
                                                        required={true}
                                                        label="Tracking ID"
                                                        size="medium"
                                                        margin="dense"
                                                    />
                                                    {formik.touched.tracking && formik.errors.tracking && (
                                                        <FormHelperText
                                                            error={true}>
                                                            {formik.errors.tracking}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Box>

                                            <LoadingButton
                                                type="submit"
                                                size="large"
                                                color="secondary"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    py: 1.2
                                                }}
                                                fullWidth={true}
                                                loadingPosition="start"
                                                startIcon={shipmentLoading ?
                                                    <CircularProgress color="secondary"/> : null}
                                                loadingIndicator={shipmentLoading ?
                                                    <CircularProgress color="secondary"/> : null}
                                                loading={shipmentLoading}
                                                variant="contained"
                                                disableElevation={true}>
                                                {shipmentLoading ? 'Tracking...' : 'Track'}
                                            </LoadingButton>
                                        </CardContent>
                                    </Card>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{py: 4}}>
                <Container>
                    {shipmentError && (
                        <Alert sx={{mb: 2}} severity="error">
                            <AlertTitle>{shipmentError}</AlertTitle>
                        </Alert>
                    )}


                    {shipment && (
                        <Box sx={{mb: 4}}>
                            <Typography variant="h6" sx={{color: 'text.primary', mb: 4}}>
                                Shipping Details
                            </Typography>
                            <Grid container={true} spacing={4}>
                                <Grid item={true} xs={12} md={4}>
                                    <Card elevation={1} sx={{
                                        height: '100%', borderBottomWidth: 2,
                                        borderBottomColor: 'colors.green',
                                        borderBottomStyle: 'solid'
                                    }}>
                                        <CardContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                    Sender
                                                </Typography>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Name
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.sender?.name}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Phone
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.sender?.phone}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Email
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.sender?.email}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Card elevation={1} sx={{
                                        height: '100%', borderBottomWidth: 2,
                                        borderBottomColor: 'colors.green',
                                        borderBottomStyle: 'solid'
                                    }}>
                                        <CardContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                    Recipient
                                                </Typography>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Name
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.recipient?.name}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Phone
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.recipient?.phone}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Email
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.recipient?.email}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Card elevation={1} sx={{
                                        height: '100%', borderBottomWidth: 2,
                                        borderBottomColor: 'colors.green',
                                        borderBottomStyle: 'solid'
                                    }}>
                                        <CardContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                    Shipping Information
                                                </Typography>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Mode
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.mode}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Status
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.status}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Total Cost
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {currencyFormatter.format(shipment?.totalCost?.amount, {code: shipment?.totalCost?.currency})}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {shipment && (
                        <Box sx={{mb: 4}}>
                            <Typography variant="h6" sx={{color: 'text.primary', mb: 4}}>
                                Address Information
                            </Typography>
                            <Grid container={true} spacing={4}>
                                <Grid item={true} xs={12} md={6}>
                                    <Card elevation={1} sx={{
                                        height: '100%', borderBottomWidth: 2,
                                        borderBottomColor: 'colors.green',
                                        borderBottomStyle: 'solid'
                                    }}>
                                        <CardContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                    Destination
                                                </Typography>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Country
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.destination?.country}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        State
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.destination?.stateOrRegionOrProvince}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        City
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.destination?.city}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Address Line 1
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.destination?.address}
                                                    </Typography>
                                                </Box>
                                                {shipment?.destination && shipment.destination.addressLine2 && (
                                                    <Box>
                                                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                            Address Line 2
                                                        </Typography>
                                                        <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                            {shipment?.destination?.addressLine2}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Card elevation={1} sx={{
                                        height: '100%', borderBottomWidth: 2,
                                        borderBottomColor: 'colors.green',
                                        borderBottomStyle: 'solid'
                                    }}>
                                        <CardContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                    Origin
                                                </Typography>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Country
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.origin?.country}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        State
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.origin?.stateOrRegionOrProvince}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        City
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.origin?.city}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                                        Address Line 1
                                                    </Typography>
                                                    <Typography variant="body1" sx={{color: 'text.primary'}}>
                                                        {shipment?.origin?.address}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {shipment && (
                        <Box sx={{mb: 4}}>
                            <Typography variant="h5" sx={{color: 'text.primary', mb: 2}}>Packages</Typography>
                            <TableContainer component={Paper} elevation={1}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Content</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Weight</TableCell>
                                            <TableCell>Dimensions</TableCell>
                                            <TableCell>Insured</TableCell>
                                            <TableCell>Fragile</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shipment && shipment.packages.map(p => {
                                            return (
                                                <TableRow key={p._id}>
                                                    <TableCell>
                                                        {`${p.quantity} X ${p.content}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {currencyFormatter.format(p?.value?.amount, {code: p?.value?.currency})}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${p.weight.amount} ${p.weight.unit}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${p.length.amount}${p.length.unit} X ${p.width.amount}${p.width.unit} X ${p.height.amount}${p.height.unit}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {p.extras.insured ? 'Yes' : 'No'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {p.extras.fragile ? 'Yes' : 'No'}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}

                    {shipment && (
                        <Stepper
                            nonLinear={true}
                            activeStep={getActiveStep(shipment.stages) - 1}
                            orientation="vertical">
                            {shipment?.stages.map((stage) => {
                                return (
                                    <Step
                                        expanded={true}
                                        index={stage.step - 1}
                                        last={stage?.step === shipment?.stages?.length - 1}
                                        disabled={stage.completed}
                                        active={stage.step === getActiveStep(shipment.stages)}
                                        completed={stage.completed}
                                        key={stage.step}>
                                        <StepLabel>
                                            <Typography variant="h6" sx={{color: "text.primary"}}>
                                                {stage.title}
                                            </Typography>
                                        </StepLabel>
                                        <StepContent>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                                    {stage.description}
                                                </Typography>
                                                {stage.additional_information && (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{color: "secondary.main", fontWeight: "bold"}}>
                                                        {stage.additional_information}
                                                    </Typography>
                                                )}
                                                {stage.date && (
                                                    <Typography variant="body2" sx={{color: "text.secondary"}}>
                                                        {moment(stage.date).fromNow()}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </StepContent>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    )}

                </Container>
            </Box>
        </Layout>
    )
}

export default TrackingPage;
