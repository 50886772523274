import {Card, CardContent, Stack, Typography} from "@mui/material";

const Expertise = ({expertise}) => {
    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="row" spacing={3}>
                    {expertise.icon}
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            {expertise.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{color: 'text.secondary'}}>
                            {expertise.description}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Expertise;
